<template>
    <div class="food zhong">
        <!--    头部导航    ?-->
        <hd-com></hd-com>
        <!--    banner    ?-->
        <div class="banner">
            <div class="banner-text">
                <template v-if="type === 'Meridian'">
                    <h3>{{ meridianDetail.meridianName }}</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a href="javascript:void(0);">经络</a><i class="el-icon-arrow-right"></i><a>{{
                            meridianDetail.meridianName
                    }}</a></p>
                </template>
                <template v-if="type === 'Acupoint'">
                    <h3>{{ acupointDetail.acupointName }}</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a href="javascript:void(0);">穴位</a><i class="el-icon-arrow-right"></i><a>{{
                            acupointDetail.acupointName
                    }}</a></p>
                </template>
                <template v-if="type === 'Manipulation'">
                    <h3>{{ manipulationDetail.massageName }}</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a href="javascript:void(0);">推拿手法</a><i class="el-icon-arrow-right"></i><a>{{
                            manipulationDetail.massageName
                    }}</a></p>
                </template>
            </div>
        </div>
        <!--    内容部分    ?-->
        <div class="content-wrap">
            <div class="content clearfix">
                <template v-if="type === 'Meridian'">
                    <el-scrollbar class="left-nav-wrap">
                        <!-- 具体要包裹的内容 -->
                        <div class="fast-nav">
                            <ul class="timeline is-reverse">
                                <li class="timeline-item" v-for="(item, index) in meridianDetail.infos" :key="index"
                                    v-show="item.info" @click="jump(index)">
                                    <div class="timeline-item__tail"
                                        v-if="!meridianDetail.acupoints && index != meridianDetail.infos.length - 1"></div>
                                    <div class="timeline-item__tail" v-if="meridianDetail.acupoints"></div>
                                    <div class="timeline-item__node"></div>
                                    <div class="timeline-item__text" data-size="17">
                                        <h3>{{ item.title }}</h3>
                                    </div>
                                </li>
                                <li class="timeline-item" v-show="meridianDetail.acupoints != 0"
                                    @click="jump(meridianDetail.infos.length)">
                                    <div class="timeline-item__node">
                                    </div>
                                    <div class="timeline-item__text" data-size="17">
                                        <h3>关联穴位</h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-scrollbar>
                    <div class="detail-box">
                        <div class="sd-div" v-for="(item, index) in meridianDetail.infos" :key="index"
                            v-show="item.info">
                            <h3 class="sd-tit" data-size="24">{{ item.title }}</h3>
                            <div class="sd-mes">
                                <p data-size="16" v-html="replace(item.info)"></p>
                            </div>
                        </div>
                        <div class="sd-div" v-if="meridianDetail.acupoints != 0">
                            <el-collapse v-model="activeNames">
                                <el-collapse-item title="关联穴位" name="acupoint">
                                    <ul class="hxsw clearfix">
                                        <li v-for="(acupointitem, index) in meridianDetail.acupoints" :key="index">
                                            <el-button type="success" plain @click="toAcupointDetail(acupointitem.hashKey,'Acupoint')">
                                                {{ acupointitem.acupointName }}
                                            </el-button>
                                        </li>
                                    </ul>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </template>
                <template v-if="type === 'Acupoint'">
                    <el-scrollbar class="left-nav-wrap">
                        <!-- 具体要包裹的内容 -->
                        <div class="fast-nav">
                            <ul class="timeline is-reverse">
                                <li class="timeline-item" v-for="(item, index) in acupointDetail.infos" :key="index"
                                    v-show="item.info" @click="jump(index)">
                                    <div class="timeline-item__tail" v-if="index != acupointDetail.infos.length - 1"></div>
                                    <div class="timeline-item__node"></div>
                                    <div class="timeline-item__text" data-size="17">
                                        <h3>{{ item.title }}</h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-scrollbar>
                    <div class="detail-box">
                        <div class="sd-div" v-for="(item, index) in acupointDetail.infos" :key="index"
                            v-show="item.info">
                            <h3 class="sd-tit" data-size="24">{{ item.title }}</h3>
                            <div class="sd-mes">
                                <p data-size="16" v-html="replace(item.info)"></p>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="type === 'Manipulation'">
                    <el-scrollbar class="left-nav-wrap">
                        <!-- 具体要包裹的内容 -->
                        <div class="fast-nav">
                            <ul class="timeline is-reverse">
                                <li class="timeline-item" v-for="(item, index) in manipulationDetail.infos" :key="index"
                                    v-show="item.info" @click="jump(index)">
                                    <div class="timeline-item__tail" v-if="index != manipulationDetail.infos.length - 1"></div>
                                    <div class="timeline-item__node"></div>
                                    <div class="timeline-item__text" data-size="17">
                                        <h3>{{ item.title }}</h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-scrollbar>
                    <div class="detail-box">
                        <div class="sd-div" v-for="(item, index) in manipulationDetail.infos" :key="index"
                            v-show="item.info">
                            <h3 class="sd-tit" data-size="24">{{ item.title }}</h3>
                            <div class="sd-mes">
                                <p data-size="16" v-html="replace(item.info)"></p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
        <staticTime></staticTime>
    </div>
</template>

<script>
import hdCom from '../../components/header' //引入组件
import ftCom from '../../components/footer' //引入组件
import rtBar from "../../components/right";
import staticTime from "../../components/staticTime";
import { Acupoint } from '../../components/Disease/Acupoint'
import { search, toAcupoint } from '../../utils/relation'
import { loginOut } from '../../utils/userInfo'
import storage from '../../utils/customstorage'
export default {
    name: "MeridianDetail",
    components: {
        hdCom,
        ftCom,
        rtBar,
        staticTime
    },
    inject: ["reload"],
    data() {
        var acupoint = new Acupoint(this.TokenClient, this.Services.Disease)
        return {
            meridianId: this.$route.query.id,   //列表页id
            searchId: this.$route.query.searchId,    //列表页searchId
            // keyWord: this.$route.query.keyWord,   //列表页KeyWord
            type: this.$route.query.type,   //type
            hashKey: this.$route.query.hashKey,   //type
            meridianDetail: {},   //初始化详情
            acupointDetail: {},   //初始化详情
            manipulationDetail: {},   //初始化详情
            userId: this.$store.state.localData.userInfo.id,
            userName: this.$store.state.localData.userInfo.name,
            acupointDomain: acupoint,
            scroll: '',
            activeNames: ['acupoint'],
            UserType: '',
            entityData: {},
        }
    },
    created() {

    },
    methods: {
        getHeadSelected() {
            //获取选中的类别
            console.log(this.headSelected)
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".left-nav-wrap");
            let banner = document.querySelector(".banner");
            //scrollObj 这个是下图右边答题卡的div

            if (scrollY > 80) {
                banner.style.position = "fixed";
                banner.style.top = 0;
                banner.style.zIndex = 99;
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "180px";
            } else {
                banner.style.position = "initial";
                banner.style.top = "none";
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        submitSearch: function () {
            search(this.searchFrom.headSelected, this.searchFrom.keywords)
        },
        //详情获取
        getDetail: function () {
            var _this = this;
            let params = `${this.hashKey}`
            switch (_this.type) {
                case "Acupoint":
                    _this.acupointDomain.Detail(params, function (data) {
                        _this.acupointDetail = data.data
                        _this.entityData = {
                            type: "acupoint",
                            name: _this.acupointDetail.acupointName,
                            hashKey: _this.acupointDetail.hashKey,
                            id: _this.acupointDetail.acupointId
                        }
                    },
                        function (error) {
                            console.log("穴位详情请求异常:", error)
                        })
                    break;
                case "Meridian":
                    _this.acupointDomain.meridianDetail(params, function (data) {
                        _this.meridianDetail = data.data
                        _this.entityData = {
                            type: "meridian",
                            name: _this.meridianDetail.meridianName,
                            hashKey: _this.meridianDetail.hashKey,
                            id: _this.meridianDetail.meridianId
                        }
                    },
                        function (error) {
                            console.log("经络详情请求异常:", error)
                        })
                    break;

                case "Manipulation":
                    _this.acupointDomain.manipulationDetail(params, function (data) {
                        _this.manipulationDetail = data.data
                        console.log(_this.manipulationDetail)
                        _this.entityData = {
                            type: "manipulation",
                            name: _this.manipulationDetail.massageName,
                            hashKey: _this.manipulationDetail.hashKey,
                            id: _this.manipulationDetail.massageId
                        }
                    },
                        function (error) {
                            console.log("推拿手法详情请求异常:", error)
                        })
                    break;
            }
        },
        //跳转穴位详情
        toAcupointDetail(hashKey,type) {
            this.hashKey = hashKey
            this.type=type
            this.getDetail();
        },
        replace: function (content) {
            content.replace(/\n/g, '</p><p>');
            return content.replace(/iframe/g, 'video');
        },
        out() {
            loginOut()
            this.$message.success("操作成功")
            this.reload()
        },
        dataScroll: function () {
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        jump(index) {
            let jump = document.getElementsByClassName('sd-div');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop - 80;
            window.scroll(0, total)
            // Chrome
            //document.body.scrollTop = total;
            // Firefox
            //document.documentElement.scrollTop = total;
            // Safari
            //window.pageYOffset = total;
            // $('html, body').animate({
            // 'scrollTop': total
            // }, 400);

        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".timeline .timeline-item");
            var sections = document.getElementsByClassName('sd-div');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 80) {
                    $navs.eq(i).addClass("active").siblings().removeClass("active")
                    break;
                }
            }
        }
    },
    watch: {
        meridianDetail: function () {
            this.$nextTick(function () {
                $('video').attr('controlslist', "nodownload");
                $('video').attr('controls', "controls");
                $('video').bind('contextmenu', function () { return false; });
            });
        },
        acupointDetail: function () {
            this.$nextTick(function () {
                $('video').attr('controlslist', "nodownload");
                $('video').attr('controls', "controls");
                $('video').bind('contextmenu', function () { return false; });
            });
        },
        manipulationDetail: function () {
            this.$nextTick(function () {
                $('video').attr('controlslist', "nodownload");
                $('video').attr('controls', "controls");
                $('video').bind('contextmenu', function () { return false; });
            });
        },
        scroll: function () {
            this.loadSroll();
        },
    },
    mounted() {
        this.$bus.$emit('select', 'meridian');
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.dataScroll);
        this.getDetail();
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.dataScroll);
    },

}
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

/*banner*/
.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/zyk_banner.jpg")center no-repeat;
}

.banner-text {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 45px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text h3 span {
    font-size: 14px;
}

.banner-text p {
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 65px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

/*内容部分*/
.content-wrap {
    background: #f8f8f8;
}

.content-wrap .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: rgba(255, 255, 255, 0);
}

.detail-box {
    margin-right: 260px;
    padding: 0px 35px;
    width: 830px;
    background: #ffffff;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.sd-div {
    padding: 30px 0;
    border-top: 1px solid #e5e5e5;
}

.sd-div:first-child {
    border-top: 0px;
}

.sd-div .sd-tit {
    font-size: 24px;
    color: #333333;
    font-weight: normal;
    margin-bottom: 30px;
}

.sd-div .sd-tit.overview {
    font-size: 26px;
    color: #333333;
    padding-left: 15px;
    border-left: 3px solid #333;
    line-height: 26px;
}

.sd-div .sd-mes p {
    white-space: pre-line;
}

.hxsw li {
    margin: 2px 0;
    display: inline-block;
    height: 42px;
    /* border: 1px solid #999999; */
    padding: 0 5px;
    box-sizing: border-box;
}

.hxsw li a {
    line-height: 42px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    cursor: pointer;
}

.hxsw li:hover {
    border-color: #00ab98;
}

.hxsw li:hover a {
    color: #00ab98;
}

.zhong .hxsw li:hover {
    border-color: #b76c12;
}

.zhong .hxsw li:hover a {
    color: #b76c12;
}

.zhongyao {
    text-align: center;
}

.zhongyao li {
    float: left;
    width: 184px;
    height: 184px;
    margin: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}

.zhongyao li .zhongyao-img {
    width: 120px;
    height: 120px;
    margin: 15px auto 5px;
}

.zhongyao li .zhongyao-img img {
    width: 100%;
    height: auto;
}

.zhongyao li .zhongyao-name {
    font-size: 18px;
    text-align: center;
    color: #333333;
}

.zhongyao li:hover {
    border-color: #b76c12;
    cursor: pointer;
}

.zhongyao li:hover .zhongyao-name {
    color: #b76c12;
}

.zhongyao .more-btn {
    display: inline-block;
}

.zhong .more-btn {
    margin: 10px auto;
    display: block;
    width: 124px;
    height: 38px;
    line-height: 38px;
    background-color: #b76c12;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
}

.left-nav-wrap {
    position: absolute;
    margin-left: 950px;
    width: 260px;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    background: #FFFFFF;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
    max-height: 470px;
    overflow-x: hidden;
}

.fast-nav {
    width: 250px;
    box-sizing: border-box;
    padding: 30px 0 30px 40px;
    z-index: 10;
}

.timeline-item {
    position: relative;
    padding-bottom: 20px;
    padding-top: 10px;
    cursor: pointer;
}

.timeline-item__tail {
    position: absolute;
    top: 15px;
    left: 3px;
    height: 100%;
    border-left: 1px solid #e5e5e5;
}

.timeline-item__node {
    position: absolute;
    background-color: #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15px;
    left: -1px;
    width: 9px;
    height: 9px;
}

.timeline-item__text {
    position: relative;
    padding-left: 28px;
    top: -3px;
}

.timeline-item__text h3 {
    font-size: 18px;
    color: #333;
    font-weight: normal;
}

.timeline-item.active .timeline-item__node,
.timeline-item:hover .timeline-item__node {
    background-color: #b76c12;
}

.timeline-item.active .timeline-item__text h3,
.timeline-item:hover .timeline-item__text h3 {
    color: #b76c12;
}
</style>
<style>
.food .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.food .el-collapse-item__header:hover {
    color: #ffffff;
}

.food .el-collapse-item__wrap {
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
}

.food .el-collapse-item__content {
    padding: 15px 5px;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
